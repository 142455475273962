import React from 'react'
import { Head, Link } from '~/components'
import { PageProps } from 'gatsby'
import * as st from '~/assets/styl/PageNotFoundAndSucess.module.styl'
import logo from '~/assets/svg/logo-black.svg'

const Success = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <div>
          <img
            src={logo}
            alt="Logo do empreendimento Marina Sunshine - Open city Campeche"
          />
          <div className={st.flex}>
            <section>
              <h1 className="title large-size color-black">
                Mensagem enviada <span className="block"> com sucesso.</span>
              </h1>
              <p className="color-gray">
                Agradecemos por entrar em contato conosco.
              </p>
              <p className="color-gray">
                Recebemos sua mensagem e gostaríamos de informar que nossa
                equipe irá responder em breve.
              </p>
            </section>
            <Link href="/" className="btn btn-white">
              Voltar
            </Link>
          </div>
        </div>
      </main>
    </>
  )
}

export default Success
